import {faCheck, faXmark} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Button, Grid, Tooltip, Typography} from "@material-ui/core";
import {groupBy} from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate, useParams} from "react-router-dom";
import {axiosAPI} from "../../../api";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {UploadFileButton} from "../../../components/Buttons";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import InnerFooter from "../../../components/InnerFooter";
import {MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import {StatusLabel} from "../../../components/Status";
import useBlockUI from "../../../hooks/useBlockUI";
import {
  useFetchCurrentPage,
  usePostCurrentPage,
  useSentinelDetailAPI,
  useSentinelListAPI,
} from "../../../hooks/useSentinelAPI";
import {Thumbnail, ThumbnailDetailDialog} from "../ProjectSafetyReview/SafetyReviewComponents";
import {SectionHeader} from "./DailySafetyInspectionComponents";
import {AllDailySafetyInspections} from "./DailySafetyInspectionForms";

const onChangeSectionErrors = (
  state: Set<unknown>,
  action: {type: "addSection" | "removeSection"; data: string | number}
): Set<unknown> => {
  const result = new Set(state);

  switch (action.type) {
    case "addSection": {
      result.add(action.data);
      return result;
    }
    case "removeSection": {
      result.delete(action.data);
      return result;
    }
  }
  return state;
};

const DailySafetyInspectionDetail = (props) => {
  const {project, ...rest} = props;
  const {inspectionId} = useParams();
  const blockUI = useBlockUI();
  const [sectionErrors, setSectionErrors] = React.useReducer(onChangeSectionErrors, new Set());
  const [markCompleteConfirmationIsOpen, setMarkCompleteConfirmationIsOpen] = React.useState(false);
  const [thumbnailDetailIsOpen, setThumbnailDetailIsOpen] = React.useState(false);
  const [activeAttachment, setActiveAttachment] = React.useState(null);
  const [deleteAttachmentConfirmationIsOpen, setDeleteAttachmentConfirmationIsOpen] = React.useState(false);
  const [markUnansweredYesConfirmationIsOpen, setMarkUnansweredYesConfirmationIsOpen] = React.useState(false);
  const [markUnansweredNAConfirmationIsOpen, setMarkUnansweredNAConfirmationIsOpen] = React.useState(false);
  const [deleteInspectionConfirmationIsOpen, setDeleteInspectionConfirmationIsOpen] = React.useState(false);

  const postCurrentPage = usePostCurrentPage();
  const navigate = useNavigate();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      weather: {},
    },
  });
  const pageData = pageDataQuery.data;

  const {weather} = pageData;

  const {
    query: inspectionQuery,
    create: createInspection,
    update: updateInspection,
    delete: deleteInspection,
  } = useSentinelDetailAPI(`projects/${project.id}/safety/inspections/${inspectionId}/`, {
    initialData: {},
  });

  const inspection = inspectionQuery.data;

  const {query: questionQuery, update: questionUpdate} = useSentinelListAPI(
    `projects/${project.id}/safety/inspections/${inspectionId}/questions/`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );

  const {
    query: attachmentQuery,
    delete: deleteAttachment,
    update: updateAttachment,
  } = useSentinelListAPI(`projects/${project.id}/safety/inspections/${inspectionId}/attachments/`, {
    initialData: {results: []},
    enabled: true,
  });

  const attachments = attachmentQuery.data.results;

  const {query: thumbnailQuery} = useSentinelListAPI(
    `projects/${project.id}/safety/inspections/${inspectionId}/attachments/thumbnails/`,
    {
      initialData: [],
      enabled: true,
    }
  );

  const refreshAttachments = () => Promise.all([attachmentQuery.refetch(), thumbnailQuery.refetch()]);
  const thumbnails = thumbnailQuery.data;

  const questions = questionQuery.data.results;

  const groupedQuestions = groupBy(questions, "section");

  const allQueriesFinished = inspectionQuery.isFetchedAfterMount && questionQuery.isFetchedAfterMount;

  const attachmentsWithThumbnails = groupBy(
    attachments.map((att) => ({...att, thumbnail: thumbnails.find((thumbnail) => thumbnail.id === att.file)})),
    "safety_inspection_question"
  );
  const generalAttachments = attachmentsWithThumbnails["null"] || [];

  const submitFormRef = React.useRef(new Set());

  if (!allQueriesFinished) {
    return <BlockUI show={true} message={`Fetching Safety Inspection Details...`} />;
  }
  const mondayInspectionWeek = moment(inspection.inspection_datetime)
    .startOf("week")
    .add(1, "days")
    .format("YYYY-MM-DD");
  // const nextError = sectionErrors.values().next().value;
  return (
    <>
      <Helmet title={`Daily Safety Inspection`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textSecondary">Safety</Typography>
        <MuiNavLink
          color="inherit"
          to={`/v2/projects/${project.id}/safety/inspections/#week-of=${mondayInspectionWeek}`}
        >
          Daily Safety Inspections
        </MuiNavLink>
        <Typography color="textPrimary">{inspection.display}</Typography>
      </ProjectBreadcrumbs>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <StatusLabel status={inspection.status_display} /> Daily Safety Inspection
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                onClick={() => setMarkUnansweredYesConfirmationIsOpen(true)}
                disabled={inspection.is_closed}
                startIcon={<FontAwesomeIcon icon={faCheck} fixedWidth size="sm" />}
              >
                Mark Unanswered as Yes
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                onClick={() => setMarkUnansweredNAConfirmationIsOpen(true)}
                disabled={inspection.is_closed}
                startIcon={<FontAwesomeIcon icon={faXmark} fixedWidth size="xs" />}
              >
                Mark Unanswered as N/A
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.PDFButton href={inspection.report_url} target="_blank" />
            </PaperPanel.Header.Action>
            {inspection.status === "pending" && (
              <PaperPanel.Header.Action border pr={0}>
                <PaperPanel.Header.Menu>
                  {(popupState) => (
                    <div>
                      <span>
                        <PaperPanel.Header.Menu.MenuItem
                          onClick={() => {
                            setDeleteInspectionConfirmationIsOpen(true);
                            popupState.close();
                          }}
                          // disabled={!safetyReview.can_delete}
                        >
                          Delete Inspection
                        </PaperPanel.Header.Menu.MenuItem>
                      </span>
                    </div>
                  )}
                </PaperPanel.Header.Menu>
              </PaperPanel.Header.Action>
            )}
            <PaperPanel.Header.Action>
              <Box mx={-1}>
                <PaperPanel.Header.RefreshButton
                  onClick={() => {
                    Promise.all([
                      inspectionQuery.refetch(),
                      questionQuery.refetch(),
                      attachmentQuery.refetch(),
                      thumbnailQuery.refetch(),
                      pageDataQuery.refetch(),
                    ]);
                  }}
                  isFetching={
                    inspectionQuery.isFetching ||
                    questionQuery.isFetching ||
                    attachmentQuery.isFetching ||
                    thumbnailQuery.isFetching ||
                    pageDataQuery.isFetching
                  }
                />
              </Box>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body p={1}>
          <AllDailySafetyInspections
            questions={groupedQuestions}
            setSectionErrors={setSectionErrors}
            onQuestionSubmit={(values) => {
              questionUpdate.mutateAsync(values);
            }}
            onInspectionSubmit={(values) => {
              updateInspection.mutateAsync(values);
            }}
            readOnly={inspection.is_closed}
            refreshAttachments={refreshAttachments}
            refreshQuestions={() => questionQuery.refetch()}
            onAttachmentSubmit={(values) => updateAttachment.mutateAsync(values)}
            onDeleteAttachment={(attachment) => deleteAttachment.mutateAsync(attachment.id)}
            initialFormValues={{...inspection}}
            projectId={project.id}
            attachmentsWithThumbnails={attachmentsWithThumbnails}
            activeAttachment={activeAttachment}
            setActiveAttachment={setActiveAttachment}
            setThumbnailDetailIsOpen={setThumbnailDetailIsOpen}
            submitFormRef={submitFormRef}
            weather={weather}
          />
          {allQueriesFinished && (
            <>
              <SectionHeader mx={2}>
                <Typography variant="h6" component="span">
                  General Attachments
                </Typography>
              </SectionHeader>
              {/* {permissions.can_change_safety_reviews && ( */}
              <UploadFileButton
                label="Upload Attachment"
                name="file"
                accept="image/jpeg,image/jpg,image/heic,image/png"
                onUpload={(file) => {
                  blockUI.blockUI("Uploading");
                  let formData = new FormData();
                  formData.append("file", file);
                  blockUI.blockUI("Uploading");
                  axiosAPI
                    .post(`/projects/${project.id}/safety/inspections/${inspectionId}/attachments/upload/`, formData)
                    .then(() => {
                      refreshAttachments().then(() => blockUI.unblockUI());
                    });
                }}
                disabled={inspection.is_closed}
              >
                Upload Attachment
              </UploadFileButton>
              {/* )} */}
              <Grid container spacing={2}>
                {generalAttachments.length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="h6">{pluralize("Attachment", generalAttachments.length)}:</Typography>
                  </Grid>
                )}
                {generalAttachments.map((attachment) => {
                  const {thumbnail} = attachment;

                  return (
                    thumbnail && (
                      <Grid item key={attachment.id}>
                        <Thumbnail
                          thumbnail={thumbnail}
                          attachment={attachment}
                          onClickThumbnail={(event) => {
                            setActiveAttachment(attachment);
                            setThumbnailDetailIsOpen(true);
                          }}
                          readOnly={inspection.is_closed}
                        />
                      </Grid>
                    )
                  );
                })}
              </Grid>
              <Box mb={3} />
            </>
          )}
        </PaperPanel.Body>
      </PaperPanel>
      {!inspection.is_closed && (
        <InnerFooter>
          <Box display="flex">
            <Tooltip title={sectionErrors.size > 0 ? "Please ensure all inspection items have been completed." : ""}>
              <Box>
                <Button
                  disabled={!allQueriesFinished || inspection.is_closed || sectionErrors.size > 0}
                  variant="contained"
                  color="primary"
                  startIcon={sectionErrors.size === 0 && <FontAwesomeIcon icon={faCheck} />}
                  onClick={() => setMarkCompleteConfirmationIsOpen(true)}
                  // onClick={() => {
                  //   if (sectionErrors.size === 0) {
                  //     setMarkCompleteConfirmationIsOpen(true);
                  //   } else {
                  //     submitFormRef.current.forEach((form: FormApi) => {
                  //       const {hasValidationErrors} = form.getState();
                  //       if (hasValidationErrors) {
                  //         form.submit();
                  //       }
                  //     });
                  //   }
                  // }}
                >
                  {/* {sectionErrors.size > 0 ? "Highlight Missing Items" : "Mark Complete"} */}
                  Mark Complete
                </Button>
              </Box>
            </Tooltip>
          </Box>
        </InnerFooter>
      )}
      <ConfirmationDialog
        onDeny={() => setMarkCompleteConfirmationIsOpen(false)}
        isOpen={markCompleteConfirmationIsOpen}
        onApprove={() => {
          blockUI.blockUI("Marking Complete...");
          postCurrentPage.mutateAsync({action: "mark_complete"}).then((response) => {
            setMarkCompleteConfirmationIsOpen(false);
            // enqueueSnackbar(response.message, {
            //   variant: "success",
            // });
            blockUI.unblockUI();
            window.location.reload();
          });
        }}
        title="Mark Safety Inspection Complete?"
      >
        This safety inspection will be finalized and no further changes can be made. Are you sure you want to continue?
      </ConfirmationDialog>

      <ThumbnailDetailDialog
        thumbnail={activeAttachment?.thumbnail ?? {}}
        attachment={activeAttachment}
        isOpen={thumbnailDetailIsOpen}
        handleClose={() => setThumbnailDetailIsOpen(false)}
        initialValues={activeAttachment}
        readOnly={inspection.is_closed}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          updateAttachment.mutateAsync(values).then(() => {
            thumbnailQuery.refetch().then(() => {
              setThumbnailDetailIsOpen(false);
              blockUI.unblockUI();
            });
          });
        }}
        onDelete={() => {
          setDeleteAttachmentConfirmationIsOpen(true);
        }}
      />
      <ConfirmationDialog
        isOpen={deleteAttachmentConfirmationIsOpen}
        onDeny={() => setDeleteAttachmentConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          setDeleteAttachmentConfirmationIsOpen(false);
          axiosAPI
            .delete(`/projects/${project.id}/safety/inspections/${inspectionId}/attachments/${activeAttachment.id}/`)
            .then(() => {
              refreshAttachments().then(() => {
                blockUI.unblockUI();
                setThumbnailDetailIsOpen(false);
                setDeleteAttachmentConfirmationIsOpen(false);
              });
            });
        }}
        title="Delete Attachment?"
      >
        Are you sure you want to delete this attachment?
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={markUnansweredYesConfirmationIsOpen}
        onDeny={() => setMarkUnansweredYesConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Saving...");
          axiosAPI
            .post(`/projects/${project.id}/safety/inspections/${inspectionId}/set-unanswered-to-yes/`)
            .then(() => {
              Promise.all([questionQuery.refetch(), inspectionQuery.refetch()]).then(() => {
                setMarkUnansweredYesConfirmationIsOpen(false);
                blockUI.unblockUI();
              });
            });
        }}
        title="Mark all unanswered as yes?"
      >
        All questions that have not been answered and saved will be marked as "Yes" indicating that they were in
        compliance at the time of the Daily Safety Inspection.
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={markUnansweredNAConfirmationIsOpen}
        onDeny={() => setMarkUnansweredNAConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Saving...");
          axiosAPI.post(`/projects/${project.id}/safety/inspections/${inspectionId}/set-unanswered-to-na/`).then(() => {
            Promise.all([questionQuery.refetch(), inspectionQuery.refetch()]).then(() => {
              setMarkUnansweredNAConfirmationIsOpen(false);
              blockUI.unblockUI();
            });
          });
        }}
        title="Mark all unanswered as N/A?"
      >
        All questions that have not been answered and saved will be marked as "N/A" indicating that they are Not
        Applicable to this inspection.
      </ConfirmationDialog>
      <ConfirmationDialog
        onDeny={() => setDeleteInspectionConfirmationIsOpen(false)}
        isOpen={deleteInspectionConfirmationIsOpen}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          setDeleteInspectionConfirmationIsOpen(false);
          deleteInspection.mutateAsync().then(() => {
            navigate(`/v2/projects/${project.id}/safety/inspections/`);
            blockUI.unblockUI();
          });
        }}
        title="Delete Safety Inspection?"
      >
        You want to delete this safety inspection? This will delete all questions, responses, and attachments. This can
        not be undone.
      </ConfirmationDialog>
    </>
  );
};

export default DailySafetyInspectionDetail;
