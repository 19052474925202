import {
  KeyboardDatePicker as FinalKeyboardDatePicker,
  KeyboardDateTimePicker as FinalKeyboardDateTimePicker,
} from "mui-rff";

export const DatePickerMui = (props) => {
  const {name, label, validateFields = [], fieldProps, ...rest} = props;
  return (
    <FinalKeyboardDatePicker
      label={label}
      name={name}
      disableToolbar={false}
      showTodayButton={true}
      clearable={true}
      inputVariant="outlined"
      format="YYYY-MM-DD"
      size="small"
      // fieldProps={{
      //   parse: identity,
      //   validate: (value) => {
      //     if (props.required) {
      //       return value ? undefined : "Required";
      //     }
      //   },
      //   validateFields: validateFields,
      //   ...fieldProps,
      // }}
      fieldProps={{
        parse: (value) => {
          if (!value) {
            return null;
          }
          if (value.isValid()) {
            return value.format("YYYY-MM-DD");
          } else {
            return "Invalid Date";
          }
        },
        validate: (value) => {
          if (value === "Invalid Date") {
            return "Invalid Date";
          }
          if (props.required && !value) {
            return "Required";
          }
        },
        validateFields: validateFields,
        ...fieldProps,
      }}
      {...rest}
    />
  );
};

export const DateTimePickerMui = (props) => {
  const {name, label, validateFields = [], fieldProps, ...rest} = props;

  return (
    <FinalKeyboardDateTimePicker
      label={label}
      name={name}
      disableToolbar={false}
      showTodayButton={true}
      clearable={true}
      inputVariant="outlined"
      format="YYYY-MM-DD hh:mm a"
      size="small"
      // fieldProps={{
      //   parse: identity,
      //   validate: (value) => {
      //     if (props.required) {
      //       return value ? undefined : "Required";
      //     }
      //   },
      // }}
      // {...props}

      fieldProps={{
        parse: (value) => {
          if (!value) {
            return null;
          }
          if (value.isValid()) {
            return value;
            // return value.format("YYYY-MM-DD hh:mm a");
          } else {
            return "Invalid Date/Time";
          }
        },
        validate: (value) => {
          if (value === "Invalid Date/Time") {
            return "Invalid Date/Time";
          }
          if (props.required) {
            return value ? undefined : "Required";
          }
        },
        validateFields: validateFields,
        ...fieldProps,
      }}
      {...rest}
    />
  );
};
