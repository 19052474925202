import {faFileCertificate, faQrcode} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Button} from "@material-ui/core";
import axios from "axios";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";
import {LabelValue} from "../../components/LabelValue";
import {PageHeader} from "../../components/PageHeader";
import {StatusLabel} from "../../components/Status";
import useBlockUI from "../../hooks/useBlockUI";
import {usePostCurrentPage} from "../../hooks/useSentinelAPI";
import UnauthenticatedLayout from "../../layouts/UnauthenticatedLayout";
import {ExternalTwilioContactCertificationForm} from "./ExternalTwilioContactCertificationForms";
import moment from "moment";
import {axiosAPI} from "../../api";

const ExternalTwilioContactCertificationDetail = (props) => {
  const {twilioContact, project, certification, certifications, isReadOnly, ...rest} = props;
  const [thumbnails, setThumbnails] = React.useState([]);
  const [thumbnailsFetched, setThumbnailsFetched] = React.useState(false);
  const isSpanish = twilioContact?.language === "SP";
  const location = useLocation();
  const blockUI = useBlockUI();
  const postCurrentPage = usePostCurrentPage();
  const qsValues = qs.parse(location.search);
  const date = qsValues?.date;
  const wellnessRedirectURL = `/twilio-contact/${twilioContact.uuid}/projects/${project.uuid}/wellness-check/${date}/`;
  const certificationsListURL = `/projects/${project.uuid}/twilio-contact/${twilioContact.uuid}/certifications/?date=${date}`;

  const getThumbnails = () => {
    return axios
      .get(
        `/projects/${project.uuid}/twilio-contact/${twilioContact.uuid}/certifications/${certification.uuid}/thumbnails/`
      )
      .then((result) => {
        setThumbnails(result.data.thumbnails);
        setThumbnailsFetched(true);
      });
  };

  React.useEffect(() => {
    getThumbnails();
  }, []);
  return (
    <UnauthenticatedLayout>
      <Helmet
        title={
          isSpanish
            ? `Certificación de ${certification.certification_type_display}`
            : `${certification.certification_type_display} Certification`
        }
      />

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            {/* <FontAwesomeIcon icon={faFileCertificate} /> */}
            <StatusLabel status={certification.status_display} hint="twilioContactCertification" />
            {"  "}
            {isSpanish
              ? `Certificación de ${certification.certification_type_display}`
              : `${certification.certification_type_display} Certification`}
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader>

      {isSpanish
        ? certification.translated_auditor_comments && (
            <LabelValue label="Comentarios" value={certification.translated_auditor_comments} />
          )
        : certification.auditor_comments && <LabelValue label="Comments" value={certification.auditor_comments} />}
      <Box mb={2} />
      <ExternalTwilioContactCertificationForm
        initialValues={certification}
        onSubmit={(values) => {
          blockUI.blockUI("Saving");
          const {submitMode = "submit_certification"} = values;
          postCurrentPage
            .mutateAsync({
              action: submitMode,
              form_data: {
                ...values,
              },
            })
            .then(() => {
              if (date) {
                window.location.href = wellnessRedirectURL;
              } else {
                window.location.reload();
              }
              // blockUI.unblockUI();
            });
        }}
        onUploadThumbnail={(file) => {
          blockUI.blockUI("Uploading...");

          let formData = new FormData();
          formData.append("file", file);
          return axiosAPI
            .post(
              `/projects/${project.uuid}/twilio-contact/${twilioContact.uuid}/certifications/${certification.uuid}/`,
              formData,
              {baseURL: "/", headers: {"Content-Type": "multipart/form-data"}}
            )
            .then(() => {
              getThumbnails().then(() => {
                blockUI.unblockUI();
              });
            })
            .catch(() => {
              blockUI.unblockUI();
            });
        }}
        isReadOnly={isReadOnly}
        thumbnails={thumbnails}
        isSpanish={isSpanish}
        certifications={certifications}
        thumbnailsFetched={thumbnailsFetched}
        onDelete={() => {
          blockUI.blockUI("Deleting");
          postCurrentPage
            .mutateAsync({
              action: "delete_certification",
              certification_uuid: certification.uuid,
            })
            .then(() => {
              if (date) {
                window.location.href = wellnessRedirectURL;
              } else {
                window.location.href = `/twilio-contact/${twilioContact.uuid}/projects/${
                  project.uuid
                }/wellness-check/${moment().format("YYYY-MM-DD")}/`;
              }
            });
        }}
      />

      {qsValues?.date && (
        <>
          <Box mb={1} />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="small"
            href={certificationsListURL}
            startIcon={<FontAwesomeIcon icon={faFileCertificate} />}
          >
            {isSpanish ? "Volver a las Certificaciones" : "Back to Certifications"}
          </Button>
          <Box mb={1} />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="small"
            href={wellnessRedirectURL}
            startIcon={<FontAwesomeIcon icon={faQrcode} />}
          >
            {isSpanish ? "Volver al código QR" : "Back to QR Code"}
          </Button>
        </>
      )}
    </UnauthenticatedLayout>
  );
};

export default ExternalTwilioContactCertificationDetail;
