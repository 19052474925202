import {Box, Breadcrumbs, Grid, Typography} from "@material-ui/core";
import qs from "qs";
import React from "react";
import {Helmet} from "react-helmet";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterSearch, FilterSelect, FilterSwitch} from "../../components/Filters";
import certificationStatusChoices from "../../components/forms/choices/twilioContactCertificationStatuses.json";
import certificationTypeChoices from "../../components/forms/choices/twilioContactCertificationTypes.json";
import PaperPanel from "../../components/PaperPanel";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {TwilioContactCertificationTable} from "./TwilioContactCertificationComponents";
import {PaginationWithPageSize} from "../../components/Pagination";

const TwilioContactCertifications = (props) => {
  const {project, ...rest} = props;

  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(100);
  const [hideInvalid, setHideInvalid] = React.useState(false);

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Search",
    "Type",
    "Twilio Contact",
    "Status",
  ]);

  const filterParams = {
    q: filterOptions.Search.value,
    certification_type: filterOptions.Type.value,
    valid: hideInvalid,
    twilio_contact: filterOptions["Twilio Contact"].value,
    status: filterOptions.Status.value,
  };

  const {
    query: certificationQuery,
    create: createCertification,
    update: updateCertification,
    delete: deleteCertification,
  } = useSentinelListAPI(`safety/certifications/?page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`, {
    initialData: {
      results: [],
    },
  });
  const certifications = certificationQuery.data.results;

  return (
    <>
      <Helmet title="Twilio Contact Certifications" />
      <Breadcrumbs>
        <Typography color="textSecondary">Safety</Typography>
        <Typography color="textPrimary">Certifications</Typography>
      </Breadcrumbs>
      <PaperPanel>
        <PaperPanel.Header isLoading={certificationQuery.isFetching}>
          <PaperPanel.Header.Title>Twilio Contact Certifications</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.RefreshButton
                isFetching={certificationQuery.isFetching}
                onClick={() => certificationQuery.refetch()}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FilterSelect
                name="type"
                label="Form Type"
                value={filterOptions.Type.value}
                options={certificationTypeChoices}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Type", value, label);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FilterSelect
                name="status"
                label="Form Status"
                value={filterOptions.Status.value}
                options={certificationStatusChoices}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Status", value, label);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FilterSwitch
                name="valid"
                label="Valid Only"
                onChange={() => {
                  setPage(1);
                  setHideInvalid((currentValue) => !currentValue);
                }}
                checked={filterParams.valid}
              />
            </Grid>
          </Grid>
          <Box mt={1} />
          <Grid item xs={12} container>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" justifyContent="flex-end">
                <PaginationWithPageSize
                  count={certificationQuery.data.total_pages}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                  pageSizes={[100, 250, 500]}
                />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <TwilioContactCertificationTable
            setFilterOption={setFilterOption}
            certifications={certifications}
            getCertificationURL={(certification) => `/v2/safety/certifications/${certification.id}`}
          />
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};

export default TwilioContactCertifications;
