import {faElevator, faFileCertificate, faForklift, faQrcode, faTruckFire} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@material-ui/core";
import qs from "query-string";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";
import {PageHeader} from "../../components/PageHeader";
import {StatusLabel} from "../../components/Status";
import Typography2 from "../../components/Typography2";
import UnauthenticatedLayout from "../../layouts/UnauthenticatedLayout";
import React from "react";
import useBlockUI from "../../hooks/useBlockUI";
import {usePostCurrentPage} from "../../hooks/useSentinelAPI";

const ExternalTwilioContactCertifications = (props) => {
  const {twilioContact, project, certifications, ...rest} = props;
  const [createFormDialogIsOpen, setCreateFormDialogIsOpen] = React.useState(false);
  const isSpanish = twilioContact?.language === "SP";
  const location = useLocation();
  const qsValues = qs.parse(location.search);
  const date = qsValues?.date;
  const wellnessRedirectURL = `/twilio-contact/${twilioContact.uuid}/projects/${project.uuid}/wellness-check/${date}/`;

  const blockUi = useBlockUI();
  const postCurrentPage = usePostCurrentPage();

  type CertificationType = "forklift" | "scissor_lift" | "boom_lift";

  const createCertification = (certification_type: CertificationType) => {
    blockUi.blockUI(isSpanish ? "Creando Certificación..." : "Creating Certification...");
    setCreateFormDialogIsOpen(false);
    postCurrentPage
      .mutateAsync({action: "create_certification", certification_type: certification_type})
      .then((response) => {
        window.location.href = `/projects/${project.uuid}/twilio-contact/${twilioContact.uuid}/certifications/${response.uuid}/?date=${date}`;
        // blockUi.unblockUI();
      });
  };

  const ButtonComponent = (props: {
    certification: {
      certification_type: CertificationType;
      expiration_date?: string;
      uuid?: string;
    };
    onClickCertification?: () => void;
    href?: string;
  }) => {
    const {
      certification,
      onClickCertification,
      href = `/projects/${project.uuid}/twilio-contact/${twilioContact.uuid}/certifications/${certification.uuid}/?date=${date}`,
    } = props;
    switch (certification?.certification_type) {
      case "forklift":
        return (
          <Button
            fullWidth
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faForklift} />}
            href={href}
            onClick={onClickCertification}
          >
            {isSpanish ? `Montacargas` : `Forklift`}
          </Button>
        );
      case "scissor_lift":
        return (
          <Button
            fullWidth
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faElevator} />}
            href={href}
            onClick={onClickCertification}
          >
            {isSpanish ? `Elevación de tijera` : `Scissor Lift`}
          </Button>
        );
      case "boom_lift":
        return (
          <Button
            fullWidth
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faTruckFire} />}
            href={href}
            onClick={onClickCertification}
          >
            {isSpanish ? `Elevación de pluma` : `Boom Lift`}
          </Button>
        );
    }
  };

  return (
    <UnauthenticatedLayout>
      <Helmet title={isSpanish ? `Tus certificaciones` : `Your Certifications`} />

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faFileCertificate} />
            {isSpanish ? `Tus certificaciones` : `Your Certifications`}
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader>
      <Grid container spacing={2}>
        {certifications.length === 0 && (
          <Grid item xs={12}>
            <Box textAlign="center" my={5}>
              <Typography variant="h4">
                {isSpanish ? "No se encontraron certificaciones" : "No Certifications Found"}
              </Typography>
            </Box>
          </Grid>
        )}
        {certifications.map((certification) => {
          return (
            <Grid item xs={12} key={certification.id}>
              <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
                <ButtonComponent certification={certification} />
                <StatusLabel status={certification.status_display} hint="twilioContactCertification" width="100%" />
                {certification?.expiration_date && (
                  <Box width={1} display="flex" justifyContent="center">
                    <Typography2
                      type={
                        certification.status === "expired" || certification.days_until_expiration < 30
                          ? "error"
                          : "metadata"
                      }
                    >
                      {isSpanish ? `Vence` : `Expiration`}: {certification.expiration_date}
                    </Typography2>
                  </Box>
                )}
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Box mb={1} mt={3}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="small"
          startIcon={<FontAwesomeIcon icon={faFileCertificate} />}
          onClick={() => setCreateFormDialogIsOpen(true)}
        >
          {isSpanish ? "Nueva Certificación" : "New Certification"}
        </Button>
      </Box>
      {qsValues?.date && (
        <>
          <Box mb={1} />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="small"
            href={wellnessRedirectURL}
            startIcon={<FontAwesomeIcon icon={faQrcode} />}
          >
            {isSpanish ? "Volver al código QR" : "Back to QR Code"}
          </Button>
        </>
      )}

      <Dialog open={createFormDialogIsOpen} onClose={() => setCreateFormDialogIsOpen(false)}>
        <DialogTitle>{isSpanish ? "Crear Certificacion" : "Create Certification"}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {isSpanish
              ? "Seleccione el tipo de certificacion que desea crear"
              : "Select the type of certification you'd like to create"}
          </Typography>
          {["forklift", "scissor_lift", "boom_lift"].map((certification_type: CertificationType) => {
            return (
              <Box my={2} key={certification_type}>
                <ButtonComponent
                  certification={{certification_type: certification_type}}
                  onClickCertification={() => createCertification(certification_type)}
                  href={"#"}
                />
              </Box>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateFormDialogIsOpen(false)}>{isSpanish ? "Cancelar" : "Cancel"}</Button>
        </DialogActions>
      </Dialog>
    </UnauthenticatedLayout>
  );
};

export default ExternalTwilioContactCertifications;
